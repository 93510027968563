import MyG from "../../assets/images/ListHeader.jfif"
import './style.scss';

const AssignHeader = () => {
    return(
      <div >
          <img src={MyG} alt="HelloDrearUser" className='LAssignH__Hello'/>
      </div>
    )
};
export default AssignHeader;